import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0_react-dom@18.3.1_react@18.3.1_sass@1.80.3/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/actions/Button/Button.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/common/AnimateIn.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/common/AnimateTitle.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/common/Image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/common/Video.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/form/TextInput/TextInput.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/Accordion/Accordion.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/SplitBlock/SplitBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/Heading/Heading.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/Work/Work.module.scss");
